/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const jquery = require('jquery');

if (typeof window !== 'undefined') {
  window.$ = jquery;
  window.jQuery = jquery;

  // foundation requires window.jQuery to exists
  require('popper.js');
  require('bootstrap');
  require('ekko-lightbox');
}

exports.onClientEntry = () => {};

exports.onInitialClientRender = () => {};

exports.onRouteUpdate = () => {};
