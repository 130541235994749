// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-booking-page-js": () => import("./../src/templates/BookingPage.js" /* webpackChunkName: "component---src-templates-booking-page-js" */),
  "component---src-templates-booking-success-page-js": () => import("./../src/templates/BookingSuccessPage.js" /* webpackChunkName: "component---src-templates-booking-success-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-rooms-page-js": () => import("./../src/templates/RoomsPage.js" /* webpackChunkName: "component---src-templates-rooms-page-js" */),
  "component---src-templates-trebon-page-js": () => import("./../src/templates/TrebonPage.js" /* webpackChunkName: "component---src-templates-trebon-page-js" */)
}

